import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaBackward, FaPlay, FaPause, FaForward, FaHeart } from 'react-icons/fa';
import '../App.css'; // Import the CSS file
import { createPortal } from 'react-dom'; // Import createPortal for rendering hearts outside the main component

const stations = [
  { name: 'Bombaclat Radio', url: 'https://azura.radiogen.io/listen/bombaclat/radio.mp3', albumArt: 'https://i.ibb.co/6Zm3vwq/6d4f2191-a5a6-4bb8-b4b7-adb84a39db5b-LOOP-online-video-cutter-com.gif' },
  { name: 'Swedish Rap', url: 'https://azura.radiogen.io/listen/swedish/radio.mp3', albumArt: 'https://gcdnb.pbrd.co/images/EAUWAjUGYdto.webp?o=1' },
  { name: 'Techno 24/7', url: 'https://azura.radiogen.io/listen/techno/radio.mp3', albumArt: 'https://gcdnb.pbrd.co/images/NDM3lt4L8Qns.png?o=1' },
  { name: 'DNB Radio', url: 'https://azura.radiogen.io/listen/dnb/radio.mp3', albumArt: 'https://gcdnb.pbrd.co/images/nCk2wuqF6bre.webp?o=1' },
  { name: 'Synthwave Radiostation', url: 'https://azura.radiogen.io/listen/synthwave/radio.mp3', albumArt: 'https://i.ibb.co/D7Sgxr3/Gen-24257680261animationsynthwave-DALLE2024-06-1017-M5-LOOP-ezgif-com-video-to-gif-converter.gif' }
];

const RadioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [currentStation, setCurrentStation] = useState(stations[0]);
  const audioRefs = useRef(stations.map(() => new Audio()));
  const [hearts, setHearts] = useState([]);
  const [currentSong, setCurrentSong] = useState('');

  useEffect(() => {
    audioRefs.current.forEach(audio => {
      audio.volume = volume;
    });
  }, [volume]);

  useEffect(() => {
    const currentAudio = audioRefs.current[stations.indexOf(currentStation)];
    currentAudio.src = currentStation.url;

    if (isPlaying) {
      currentAudio.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    } else {
      currentAudio.pause();
    }

    // Pause all other audio elements
    audioRefs.current.forEach((audio, index) => {
      if (stations[index] !== currentStation) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
  }, [isPlaying, currentStation]);

  useEffect(() => {
    const fetchCurrentSong = async () => {
      try {
        const response = await fetch('https://azura.radiogen.io/api/nowplaying');
        const data = await response.json();
        const stationData = data.find(station => station.station.listen_url === currentStation.url);
        if (stationData && stationData.now_playing.song) {
          setCurrentSong(stationData.now_playing.song.title);
          document.title = `${stationData.now_playing.song.title} - ${currentStation.name}`;
        } else {
          setCurrentSong('No song information available');
        }
      } catch (error) {
        console.error('Error fetching current song:', error);
        setCurrentSong('Error fetching song');
      }
    };

    fetchCurrentSong();
    const intervalId = setInterval(fetchCurrentSong, 10000);

    return () => clearInterval(intervalId);
  }, [currentStation]);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const changeVolume = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
  };

  const changeStation = (station) => {
    setCurrentStation(station);
    setIsPlaying(true); // Autoplay the new station
  };

  const renderStationCards = () => {
    return stations.map((station, index) => (
      <div key={index} className={`RadioPlayer-station-card ${currentStation === station ? 'RadioPlayer-station-card-active' : ''}`} onClick={() => changeStation(station)}>
        <img src={station.albumArt} alt={`${station.name} album art`} className="RadioPlayer-station-card-image" />
        <p>{station.name}</p>
      </div>
    ));
  };

  const handleHeartClick = () => {
    const newHearts = Array.from({ length: 5 }, () => ({ id: Date.now() + Math.random() }));
    setHearts([...hearts, ...newHearts]);
    setTimeout(() => {
      setHearts((hearts) => hearts.filter((heart) => !newHearts.some(newHeart => newHeart.id === heart.id)));
    }, 5000); // Remove hearts after animation
  };

  return (
    <>
      <div>
        <div className="RadioPlayer">
          <img src={currentStation.albumArt} alt={`${currentStation.name} album art`} className="RadioPlayer-albumArt" draggable="false" />
          <div className="RadioPlayer-station-name">
            <div className="RadioPlayer-current-song">
              {currentStation.name} {`- ${currentSong}`}
            </div>
            <div className="RadioPlayer-current-song" style={{ animationDelay: '-5s' }}>
              {currentStation.name} {`- ${currentSong}`}
            </div>
          </div>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={changeVolume}
            className="RadioPlayer-volume"
          />
          <div className="RadioPlayer-stations">
            <button className="RadioPlayer-station-button"><FaBars /></button>
            <button className="RadioPlayer-station-button"><FaBackward /></button>
            <button onClick={togglePlay} className="RadioPlayer-button">
              {isPlaying ? <FaPause /> : <FaPlay />}
            </button>
            <button className="RadioPlayer-station-button"><FaForward /></button>
            <button onClick={handleHeartClick} className="RadioPlayer-station-button"><FaHeart /></button>
          </div>
        </div>
      </div>
      <div className="RadioPlayer-station-selector">
        {renderStationCards()}
      </div>
      {createPortal(
        hearts.map((heart) => (
          <div key={heart.id} className="heart" style={{ left: `${Math.random() * 100}%`, top: `${100 + Math.random() * 50}%` }}></div>
        )),
        document.body
      )}
    </>
  );
};

export default RadioPlayer;


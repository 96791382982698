import React from 'react';
import './App.css';
import RadioPlayer from './components/RadioPlayer';

function App() {
  return (
    <div className="App">
      <RadioPlayer />
    </div>
  );
}

export default App;
